<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>{{ $t('swal.attention') }}</strong> {{ $t('sites.parsing_alert') }}</span>
      </div>
    </b-alert>
  </div>
</template>
<script>
import {
  BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
}
</script>
